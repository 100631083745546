<template>
  <iframe
    :src="`https://invoicing.dok.ma/`"
    width="100%"
    height="100%"
    frameborder="0"
  >
  </iframe>
</template>

<script>
export default {
  name: "invoicing",
};
</script>
